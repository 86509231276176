import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.1_@babel+core@7.24.7_@opentelemetry+api@1.9.0_@playwright+test@1.43.1_react-dom@18._tusrgt3hdqrsg2eedu5u4qjgse/node_modules/next/font/local/target.css?{\"path\":\"src/assets/fonts/tabloid-fonts.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":[{\"path\":\"./AtlasGrotesk/AtlasGrotesk-ThinItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"100\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-Thin-Web.woff2\",\"style\":\"normal\",\"weight\":\"100\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-LightItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"300\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-Light-Web.woff2\",\"style\":\"normal\",\"weight\":\"300\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-RegularItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"400\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-Regular-Web.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-MediumItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"500\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-Medium-Web.woff2\",\"style\":\"normal\",\"weight\":\"500\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-BoldItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"700\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-Bold-Web.woff2\",\"style\":\"normal\",\"weight\":\"700\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-BlackItalic-Web.woff2\",\"style\":\"italic\",\"weight\":\"900\"},{\"path\":\"./AtlasGrotesk/AtlasGrotesk-Black-Web.woff2\",\"style\":\"normal\",\"weight\":\"900\"}],\"variable\":\"--font-heading\"}],\"variableName\":\"atlasGrotesk\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.1_@babel+core@7.24.7_@opentelemetry+api@1.9.0_@playwright+test@1.43.1_react-dom@18._tusrgt3hdqrsg2eedu5u4qjgse/node_modules/next/font/local/target.css?{\"path\":\"src/assets/fonts/tabloid-fonts.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":[{\"path\":\"./Montserrat/Montserrat-Regular.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"./Montserrat/Montserrat-Bold.woff2\",\"style\":\"normal\",\"weight\":\"700\"},{\"path\":\"./Montserrat/Montserrat-ExtraBold.woff2\",\"style\":\"normal\",\"weight\":\"800\"}],\"variable\":\"--font-body\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager", webpackExports: ["AdSlot"] */ "/app/src/components/AdSlot/AdSlot.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Display"] */ "/app/src/components/Display/Display.component.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/app/src/components/Footer/Footer.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/app/src/components/Header/Header.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TopAd"] */ "/app/src/components/TopAd/TopAd.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StickyBanner"] */ "/app/src/layouts/Base/components/StickyBanner.component.tsx");
